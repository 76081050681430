import React from "react";
import styled from "styled-components";

export default function Tag({ tag, register, fullWidth }) {
  return (
    <CheckboxContainer fullWidth={fullWidth}>
      <label>
        <input type="checkbox" {...register(tag)} />
        <span>{tag}</span>
      </label>
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.div`
  user-select: none;
  margin: 4px;
  border-radius: 20px;
  border: 2px solid ${({ theme: { blue } }) => blue};
  overflow: auto;
  float: left;
  transition: all 0.2s ease-in-out;
  width: ${(props) => (props?.fullWidth ? "calc(100% - 4px)" : "")};

  label {
    float: left;
    width: 100%;
  }

  label span {
    text-align: center;
    padding: 5px 10px;
    display: block;
    text-transform: capitalize;
    color: ${({ theme: { blue } }) => blue};
    cursor: pointer;
    word-break: keep-all;
    word-wrap: normal;
    overflow: hidden;
  }

  label input {
    position: absolute;
    top: -20px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:checked + span {
    background-color: ${({ theme: { blue } }) => blue};
    color: #fff;
  }

  label:hover span {
    background-color: ${({ theme: { blue } }) => blue};
    color: #fff;
  }
`;
